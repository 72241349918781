import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import PageTransition from 'gatsby-plugin-page-transitions';

import "../styles/index.sass";

let lastScrollY = 0;

function TemplateWrapper(props) {
  const { children } = props;
  const [showMenu, setShowMenu] = useState(false);
  let [handleScroll, nav, navWrapper] = useState(true);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  })

  handleScroll = () => {
    lastScrollY = window.scrollY;
    navWrapper = document.getElementById('nav-wrapper');
    if (lastScrollY >= 50) {
      navWrapper.classList.add('scroll')
    } else {
      navWrapper.classList.remove('scroll')
    }
  };
  const navColorTrue = props.navColor ? ` ${props.navColor}` : " ";
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            copyright
          }
          allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                profileType
                url
              }
            }
          }
          allDatoCmsMenu(sort: {fields: [position], order: ASC}) {
            edges {
              node {
                id
                menuTitle
                linkTo
              }
            }
          }
          allDatoCmsSocial {
            edges {
              node {
                id
                socialMedia
                url
                svgCode
                transform
                transformTwo
                icon {
                  url
                }
              }
            }
          }
          datoCmsFooter {
            logo {
              url
              alt
            }
            snippet
            button
            buttonUrl
            copyrightNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      `}
      render={data => (
        <div className={`container ${showMenu ? "is-open" : ""}`}>
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
          />
          <nav id="nav" className={`navbar` + navColorTrue}>
            <div id="nav-wrapper" className="navbar_wrapper">
              <div className="navbar_brand">
                <Link to="/">{data.datoCmsSite.globalSeo.siteName}</Link>
              </div>

              <div
                className={`container ${showMenu ? "burger_open" : ""}`}
                onClick={e => {
                  e.preventDefault();
                  setShowMenu(!showMenu);
                }}
              >
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
              </div>

              <ul className="navbar_items">
                {data.allDatoCmsMenu.edges.map(({ node: menu }) => (
                  <li id={menu.id}>
                    <Link to={menu.linkTo} activeClassName="active">{menu.menuTitle}</Link>
                  </li>
                ))}
                {data.allDatoCmsSocial.edges.map(({ node: social }) => (
                  <li id={social.id}>
                    <a href={social.url} className="social-icon-hover" target="_blank" rel="noopener noreferrer">
                      <svg version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="social-icon">
                        <title>{social.socialMedia}</title>
                        <g fill-rule="evenodd">
                          <g transform={social.transform} fill-rule="nonzero">
                            <g transform={social.transformTwo}>
                              <path d={social.svgCode} />
                            </g>
                          </g>
                        </g>
                      </svg>
                      {social.socialMedia}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          <PageTransition>
            <div className="container__body">{children}</div>
          </PageTransition>

          <footer className="footer_end">
            <div className="footer_content">
              <div className="footer-logo" style={{ width: "140px", paddingRight: "15px", paddingLeft: "15px" }}>
                <div className="navbar_brand">
                  <Link to="/" className="text-bold" style={{ fontSize: "24px" }}>{data.datoCmsSite.globalSeo.siteName}</Link>
                </div>
              </div>
              <div class="text-bold" style={{
                fontSize: "24px",
                lineHeight: "32px",
                paddingLeft: "15px",
                paddingRight: "15px",
                width: "360px"
              }}>
                {data.datoCmsFooter.snippet}
              </div>

              <div className="footer_copyright" style={{ paddingLeft: "15px" }}>
                <Link to={data.datoCmsFooter.buttonUrl}>
                  <button className="button btn-arrow" style={{
                    border: "2px solid #CC8E00",
                    borderRadius: "4px",
                    padding: "0.5rem 1rem",
                    background: "transparent",
                    color: "#CC8E00"
                  }}>
                    {data.datoCmsFooter.button}
                  </button>
                </Link>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data.datoCmsFooter.copyrightNode.childMarkdownRemark.html
                  }}
                />
              </div>
            </div>
          </footer>
        </div>
      )}
    />
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.object
};

export default TemplateWrapper;
